import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form" ];

  #answer_tree = [];
  #script;

  connect() {
    this.#script = document.getElementById("vat-rate-tree");

    if (this.#script) {
      this.#answer_tree.push(JSON.parse(this.#script.innerHTML));
    }

    this._appendQuestionToForm(this.#answer_tree[0].id, this.#answer_tree[0].question, this.#answer_tree[0].label, this.#answer_tree[0].children, this.#answer_tree[0].information);
  }

  updateQuestions(event) {
    if (this._containsAnswer(event.currentTarget.name)) { // Previous question is updated
      this._restartAnswerTree(event.currentTarget)
      this._resetForm()
    } else { // New question is answered
      let question = this.#answer_tree[this.#answer_tree.length - 1]

      question.children?.forEach((child) => {
        if (child.id == event.currentTarget.value) {
          question = child
          this.#answer_tree.push(child)
        }
      });

      if (question.question == null) {
        this._showAdvice()
      } else {
        this._appendQuestionToForm(question.id, question.question, question.label, question.children, question.information)
      }
    }
  }

  _appendQuestionToForm(name, question, label_text, children, information) {
    const is_first_question = document.getElementById("question0-div") == undefined
    const input_field = document.createElement("div")
    input_field.setAttribute("id", `${name}-div`)
    input_field.setAttribute("class", "mt-8")

    input_field.innerHTML = `<p class="font-medium text-lg"> ${question}</p>`
    if (information) {
      input_field.innerHTML += `<p class="font-light text-sm"> ${information}</p>`
    }

    children.forEach((child) =>
    input_field.innerHTML += `
      <p class="flex items-center leading-10 py-1">
        <input class="flex-[0_0_auto]" type="radio" id="${child.id}" name="${name}" value="${child.id}" data-action="click->vat-calculator#updateQuestions"/>
        <label class="flex-auto ml-4 text-base" for=${child.id}>${child.label}</label>
      </p>
    `
    );

    this.formTarget.appendChild(input_field)
    if (!is_first_question) input_field.scrollIntoView({ behavior: 'smooth'});
  }

  _showAdvice() {
    const advice = this.#answer_tree[this.#answer_tree.length - 1].children[0]
    const advice_paragraph = document.createElement("div")
    advice_paragraph.setAttribute("id", `${advice.id}-div`)

    advice_paragraph.innerHTML = `
      <hr class="my-4" />
      <h2>Advies</h2>
      <p>${advice.label}</p>
    `

    this.formTarget.appendChild(advice_paragraph)
    advice_paragraph.scrollIntoView({ behavior: 'smooth'});
  }

  _containsAnswer(answer_id) {
    let contains_answer = false

    this.#answer_tree.slice(0, -1).forEach((answer) => { // Never check the last item, this is always an unanswered question
      if (answer.id == answer_id) {
        contains_answer = true
      }
    });

    return contains_answer
  }

  _restartAnswerTree(targetQuestion) {
    while (this.#answer_tree[this.#answer_tree.length - 1].id != targetQuestion.name) {
      this.#answer_tree.pop() // Remove all answers that are not children of the newly selected answer
    }

    this.#answer_tree[this.#answer_tree.length - 1].children.forEach((answer) => {
      if (answer.id == targetQuestion.id) {
        this.#answer_tree.push(answer) // Push the newly selected answers to the answer tree
        return;
      }
    });
  }

  _resetForm() {
    let question_ids = Array.from(this.formTarget.children).map( (question) => question.id)
    let answer_tree_ids = this.#answer_tree.map( (answer) => `${answer.id}-div`)

    this._removeOldQuestions(question_ids, answer_tree_ids)
    this._addAnswers(question_ids)
  }

  _removeOldQuestions(question_ids, answer_tree_ids) {
    question_ids.forEach((question_id, i) => {
      if (answer_tree_ids.includes(question_id)) {
        return; // If the question is still in the answer tree, don't remove it
      }

      document.getElementById(`${question_id}`).remove()
    });
  }

  _addAnswers(question_ids) {
    this.#answer_tree.forEach((answer) => {
      if (question_ids.includes(`${answer.id}-div`)) {
        return; // If the answer is already in the answer tree, don't add it
      }

      if (answer.question == null) {
        this._showAdvice()
      } else {
        this._appendQuestionToForm(answer.id, answer.question, answer.label, answer.children, answer.information)
      }
    });
  }
}
