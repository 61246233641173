/* @flow */

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item", "glow", "name"];

  #bounds;
  #isFlipped = false;

  connect() {
    this.#bounds = this.itemTarget.getBoundingClientRect();

    this.itemTarget.addEventListener("mousemove", this.rotateToMouse.bind(this));
    this.itemTarget.addEventListener("touchmove", this.rotateToMouse.bind(this));
    this.itemTarget.addEventListener("mouseleave", this._clear.bind(this));
    this.itemTarget.addEventListener("touchcancel", this._clear.bind(this));
    this.itemTarget.addEventListener("touchend", this._clear.bind(this));
    const bankingSignupDurationCalculator = this.element.parentElement.parentElement;

    bankingSignupDurationCalculator.addEventListener("banking-signup-duration-calculator:select", (event) => {
      this.flip();
      this.nameTarget.textContent = event.detail.name;
    });
    bankingSignupDurationCalculator.addEventListener("banking-signup-duration-calculator:reset", () => {
      this.flip();
    });
  }

  disconnect() {
    this.itemTarget.removeEventListener("mousemove", this.rotateToMouse);
    this.itemTarget.removeEventListener("touchmove", this.rotateToMouse);
    this.itemTarget.removeEventListener("mouseleave", this._clear);
    this.itemTarget.removeEventListener("touchcancel", this._clear);
    this.itemTarget.removeEventListener("touchend", this._clear);
    this.itemTarget.removeEventListener("click", this.flip);
  }

  rotateToMouse(event) {
    const mouseX = event.clientX;
    const mouseY = event.clientY;
    const leftX = mouseX - this.#bounds.x;
    const topY = mouseY - this.#bounds.y;
    const center = {
      x: leftX - this.#bounds.width / 2,
      y: topY - this.#bounds.height / 2,
    };
    if (this.#isFlipped) {
      center.x = -center.x;
    }
    const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

    const tiltRotation = Math.log(distance) * 3;

    if(this.#isFlipped) {
      this.itemTarget.style.transform = `
        scale3d(1.1, 1.1, 1.1)
        rotate3d(0, 1, 0, 180deg)
      `;
    } else {
      this.itemTarget.style.transform = `
        scale3d(1.1, 1.1, 1.1)
        rotate3d(${center.y / 100}, ${-center.x / 100}, 0, ${tiltRotation}deg)
      `;
    }

    this.glowTarget.style.backgroundImage = `
      radial-gradient(
        circle at
        ${center.x * 2 + this.#bounds.width / 2}px
        ${center.y * 2 + this.#bounds.height / 2}px,
        #ffffff3a,
        #0000000f
      )
    `;
  }

  flip() {
    this.#isFlipped = !this.#isFlipped;
    this.itemTarget.classList.toggle("flipped");
    this._clear();
  }

  _clear() {
    const baseRotation = this.#isFlipped ? 180 : 0;
    if (this.#isFlipped) {
      this.itemTarget.style.transform = `rotateY(${baseRotation}deg)`;
    } else {
      this.itemTarget.removeAttribute("style");
    }
    this.glowTarget.style.backgroundImage = "";

  }
}