import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["results", "input"];

  #autocomplete;

  connect() {
    this.element.addEventListener("search:finished", (e) => {
      this.resultsTarget.innerText = e.detail.resultsCount;

      e.stopPropagation;
      e.preventDefault;
    });

    this.element.addEventListener("search:reset", (e) => {
      this.inputTarget.value = "";
      this.resultsTarget.innerText = 0;

      e.stopPropagation;
      e.preventDefault;
    });
  }

  autocomplete(event /* event: StimulusEvent */) {
    if (typeof (google) == "undefined" || this.#autocomplete) return;

    var _addEventListener = (this.inputTarget.addEventListener) ? this.inputTarget.addEventListener : this.inputTarget.attachEvent;

    function addEventListenerWrapper(type, listener) {
      // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
      // and then trigger the original listener.
      if (type == "keydown") {
          var orig_listener = listener;
          listener = function(event) {
              var suggestion_selected = (document.querySelectorAll(".pac-item-selected") || []).length > 0;

              if (event.which == 13 && !suggestion_selected) {
                  var simulated_downarrow = new KeyboardEvent("keydown", {
                      keyCode: 40,
                      which: 40
                  });
                  orig_listener.apply(this.inputTarget, [simulated_downarrow]);
              }

              orig_listener.apply(this.inputTarget, [event]);
          };
      }

      _addEventListener.apply(this.inputTarget, [type, listener.bind(this)]);
    }

    this.inputTarget.addEventListener = addEventListenerWrapper.bind(this);
    this.inputTarget.attachEvent = addEventListenerWrapper.bind(this);

    this.#autocomplete = new google.maps.places.Autocomplete(
      event.currentTarget,
      {
        types: [ '(cities)' ],
        componentRestrictions: {
          country: 'nl'
        }
      }
    );

    this.#autocomplete.addListener("place_changed", () => {
      const place = this.#autocomplete.getPlace();

      if (place.hasOwnProperty('geometry')) {
        this.element.dispatchEvent(
          new CustomEvent('search:place_changed', { bubbles: true, detail: { location: place.geometry.location } })
        );
      }
    })
  }
}
