import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["title", "type", "identifier"];
  
  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedId = urlParams.get('id')
    const data = JSON.parse(atob(encryptedId));
    const companyTitle = data[0];
    const companyIdentifierType = data[1];
    const companyIdentifier = data[2];

    this.titleTargets.forEach((target) => {
      target.innerText = companyTitle;
    });

    this.typeTarget.innerText = companyIdentifierType;
    this.identifierTarget.innerText = companyIdentifier;
  }
}
