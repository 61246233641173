import inRange from 'lodash/inRange';

window.onRecaptchaSubmit = function(token) {
  const targets = {
    form: document.querySelector('.active-recaptcha-form'),
    errors: document.getElementById('recaptcha-form-errors'),
    success: document.getElementById('recaptcha-form-success'),
  }
  const forms = ["adviser-contact-form", "sign-up-for-moneybird-form"];
  if (!forms.includes(targets.form.attributes.id.value)) return;

  if (!(token instanceof SubmitEvent)) {
    document.getElementsByName('captcha_token')[0].value = token;
  }

  submitForm(targets, targets.form.dataset.useFetch !== "false");
}

function handleErrors(errorElement, { errors = {}, error }) {
  const messages = Object.keys(errors).map((key) =>
    `${errors[key]["name"]} ${errors[key]["errors"].join(", ")}`
  );
  if (error) messages.push(...error.split(', '));
  showErrors(errorElement, ...messages);
}

function showErrors(errorElement, ...messages) {
  if (!errorElement) return console.error(messages.join(', '));

  errorElement.innerHTML = ""
  messages.forEach((message) => {
    errorElement.insertAdjacentHTML('beforeend',`<li>${message}</li>`);
  });
  errorElement.classList.remove('hidden');
}

function handleSuccess(targets) {
  targets.success.classList.remove('hidden');
}

function getGenericErrorMessage() {
  const lang = document.documentElement.lang;
  if (lang === 'nl') return 'Er ging iets mis, probeer het opnieuw';
  return 'Something went wrong, please try again';
}

window.submitForm = async function(targets, useFetch) {
  targets.errors.classList.add('hidden');
  window.dataLayer = window.dataLayer || [];

  if (useFetch === true) {
    try {
      const response = await fetch(targets.form.attributes.action.value, {
        method: 'POST',
        credentials: 'include',
        body: new FormData(targets.form),
      });
      const result = await response.json();
      if (!inRange(response.status, 200, 300)) return handleErrors(targets.errors, result);

      targets.form.dispatchEvent(new CustomEvent('signup:success', { bubbles: true } ));
      window.dataLayer.push({ event: 'recaptcha_submit', form: targets.form.dataset.title });
      handleSuccess(targets);
    } catch (error) {
      console.error(error);
      showErrors(targets.errors, getGenericErrorMessage());
    }
  } else {
    window.dataLayer.push({ event: 'recaptcha_submit', form: targets.form.dataset.title });
    targets.form.dispatchEvent(new CustomEvent('signup:success', { bubbles: true } ));
    targets.form.submit();
  }
}
