import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "dynamicTitle" ]

  connect() {
    this._showTitle(0)
  }

  _showTitle(index) {
    this.dynamicTitleTargets.forEach((title) => {
      title.classList.remove("active");
      title.classList.add("absolute");
    });

    let title = document.getElementById(index)

    title.classList.add("active");
    title.classList.remove("absolute");

    setTimeout((index) => {
      if (index < this.dynamicTitleTargets.length - 1) {
        return this._showTitle(index + 1)
      }

      this._showTitle(0)
    }, 2500, index)
  }
}
