import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import {
  A11y,
  Autoplay,
  Navigation,
} from "swiper/modules";
import "swiper/css";

export default class extends Controller {
  static targets = ["prevBtn", "nextBtn"];

  connect() {
    this.swiper = new Swiper(this.element, {
      modules: [A11y, Autoplay, Navigation],
      ...this.defaultOptions,
    });
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }

  get defaultOptions() {
    return {
      spaceBetween: 24,
      centeredSlides: true,
      speed: 1000,
      autoplay: {
        delay: 8000,
        disableOnInteraction: true,
      },
      loop: true,
      slidesPerView: 'auto',
      allowTouchMove: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: this.nextBtnTarget,
        prevEl: this.prevBtnTarget,
      },
      a11y: {
        slideRole: 'link',
      }
    }
  }
}
