
        import * as module0 from './controllers/adviser_search_controller.js';import * as module1 from './controllers/fade_out_controller.js';import * as module2 from './controllers/growthbook_controller.js';import * as module3 from './controllers/modal_controller.js';import * as module4 from './controllers/peppol_page_controller.js';import * as module5 from './controllers/recaptcha_controller.js'
        const modules = {
            "./controllers/adviser_search_controller.js": module0,
            "./controllers/fade_out_controller.js": module1,
            "./controllers/growthbook_controller.js": module2,
            "./controllers/modal_controller.js": module3,
            "./controllers/peppol_page_controller.js": module4,
            "./controllers/recaptcha_controller.js": module5,
        };
        export default modules;
      