import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  #isLoaded;

  loadRecaptcha() {
    if (this.#isLoaded === true) return;

    this.#isLoaded = true;
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://www.google.com/recaptcha/api.js'
    head.appendChild(script)
  }

  startRecaptcha(event /* StimulusEvent */) {
    event.preventDefault();
    event.stopImmediatePropagation();

    document.querySelectorAll('form').forEach((el) => el.classList.remove('active-recaptcha-form'));

    const form = event.currentTarget.closest('form');
    form.classList.add("active-recaptcha-form");

    if (this._getRecaptchaToken().length === 0) {
      form.parentElement.insertBefore(document.getElementsByClassName('g-recaptcha')[0], form);
      grecaptcha.reset()
      grecaptcha.execute()
    } else {
      onRecaptchaSubmit(event);
    }
  }

  _getRecaptchaToken() {
    return grecaptcha.getResponse();
  }
}
