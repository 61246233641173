import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import {
  A11y,
  Autoplay,
  Controller as SwiperController,
  EffectFade,
  Pagination,
  Parallax,
} from "swiper/modules";
import "swiper/css";

import { gsap } from "gsap";

export default class extends Controller {
  static targets = ["track", "slide"];

  connect() {
    if (this.slideTargets.length < 2) return;

    const swiperController = {};
    if (window.headerTextCarousel) {
      swiperController.by = "slide";
      swiperController.control = window.headerTextCarousel;
    }

    this.swiper = new Swiper(this.element, {
      modules: [A11y, Autoplay, SwiperController, EffectFade, Pagination, Parallax],
      ...this.defaultOptions,
      ...(swiperController.control ? { controller: swiperController } : {}),
    });
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }

  get defaultOptions() {
    return {
      loop: true,
      effect: "fade",
      fadeEffect: { crossFade: true },
      parallax: true,
      speed: 1000,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        waitForTransition: false,
        stopOnLastSlide: false,
        // pauseOnMouseEnter: true,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        progressbarFillClass: "swiper-pagination-progressbar-fill",
        renderBullet: function (index, className) {
          return `<button class="${className}"><span class="sr-only">Slide ${index + 1}</span><div class="swiper-pagination-progress"></div></button>`;
        },
      },
      on: {
        autoplayTimeLeft: (swiper, time, progress) => {
          const currentBullet = this.element.querySelectorAll(
            ".swiper-pagination-progress",
          )[swiper.realIndex];
          if (!currentBullet) return;

          gsap.set(currentBullet, { width: `${(1 - progress) * 100}%` });
        },
      },
    };
  }
}
