import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["map"]

  #abroad;
  #boundariesNetherlands;
  #map;
  #markerList;

  connect() {
    if (typeof (google) != "undefined") this.initializeMap();
  }

  initializeMap() {
    if (this.#map != undefined) return;

    this.#boundariesNetherlands = new google.maps.LatLngBounds(new google.maps.LatLng(50.5, 3.5), new google.maps.LatLng(53.5, 7.2));
    this.#map = this._initiateMap(this._options(this.mapTarget));

    if (this.mapTarget.dataset.markers) {
      const locations = JSON.parse(new DOMParser().parseFromString(this.mapTarget.dataset.markers, "text/html").documentElement.textContent);
      this._loadMarkers(locations);
    }
  }

  _initiateMap(options) {
    const map = new google.maps.Map(
      options.map,
      {
        center: {
          lat: options.lat,
          lng: options.lng
        },
        zoom: options.zoom,
        zoomControlOptions: options.zoomControlOptions,
        zoomControl: options.zoomControl,
        disableDoubleClickZoom: options.disableDoubleClickZoom,
        draggable: options.draggable,
        mapTypeControl: false,
        panControl: false,
        scrollwheel: true,
        streetViewControl: false,
        styles: this._setStyle(options.style)
      }
    )
    this.#abroad = options.abroad;

    this._addMapListeners();

    this.element.dispatchEvent(new CustomEvent('map:onload', { bubbles: true, detail: { map: map} }));

    return map;
  }

  _addMapListeners() {
    this.element.addEventListener("map:move", (e) => {
      this._move(e.detail.location, e.detail.zoom);
    });

    this.element.addEventListener("map:add_markers", (e) => {
      this._loadMarkers(e.detail.markerList);
    });

    this.element.addEventListener("map:reset", (e) => {
      this._autoCenter();

      e.stopPropagation;
      e.preventDefault;
    });
  }

  _move(location, zoom) {
    this.#map.setCenter(location);
    this.#map.setZoom(zoom);
  }

  _addMarkers(map, locations) {
    return locations.map((location) => {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(location[1], location[2]),
        map: map,
        title: location[0],
        place_id: location[3],
        draggable: false,
        animation: google.maps.Animation.DROP,
        city: location[5]
      })

      this._setMarkerIconAndListener(marker, map)

      return marker
    })
  }

  _setMarkerIconAndListener(marker, map) {
    marker.setIcon({
      url: '/assets/images/google-maps/marker-default.svg',
      size: new (google.maps.Size)(32, 32),
      scaledSize: new (google.maps.Size)(32, 32)
    })

    marker.addListener("click", () => {
      map.setZoom(19)
      map.setCenter(marker.getPosition())
    });
  }

  _loadMarkers(markerList) {
    this.#markerList = this._addMarkers(this.#map, markerList);

    if (typeof(MarkerClusterer) != "undefined") {
      new MarkerClusterer(this.#map, this.#markerList, {
        styles: Array(5).fill(MarkerClusterer.withDefaultStyle({
          url: "/assets/images/google-maps/marker-cluster.png",
          textColor: "white",
          width: 53,
          height: 53
        }))
      })
    }

    this._autoCenter();

    this.element.dispatchEvent(new CustomEvent('map:markers_updated', { bubbles: true, detail: { markerList: this.#markerList} }));
  }

  _autoCenter()  {
    if (!this.#map) return;

    var bounds = new (google.maps.LatLngBounds)
    this.#markerList.forEach((marker) => {
      if (this.#abroad || this.#boundariesNetherlands.contains(marker.position))
        bounds.extend(marker.position);
    });
    this.#map.fitBounds(bounds);
    this.#map.panToBounds(bounds);

    const maxZoom = parseInt(this.mapTarget.dataset.zoom);
    google.maps.event.addListenerOnce(this.#map, 'idle', () => {
      if (this.#map.getZoom() > maxZoom) this.#map.setZoom(maxZoom)
    });
  }

  _options(mapTarget) {
    return {
      map: mapTarget,
      lat: parseFloat(mapTarget.dataset.latitude),
      lng: parseFloat(mapTarget.dataset.longtitude),
      zoom: parseFloat(mapTarget.dataset.zoom),
      style: mapTarget.dataset.style,
      draggable: true,
      zoomControl: true,
      disableDoubleClickZoom: false,
      zoomControlOptions:  {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
      abroad: (mapTarget.dataset.abroad === 'true')
    }
  }

  _setStyle(type) {
    const mapStylingDark = [
      {
        'elementType': 'geometry',
        'stylers': [ { 'color': '#233241' } ]
      },
      {
        'elementType': 'labels',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'administrative',
        'elementType': 'geometry',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'poi',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'road',
        'elementType': 'geometry.fill',
        'stylers': [ { 'color': '#2c2c2c' } ]
      },
      {
        'featureType': 'road',
        'elementType': 'labels.icon',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'road',
        'elementType': 'labels.text.fill',
        'stylers': [ { 'color': '#8a8a8a' } ]
      },
      {
        'featureType': 'road.arterial',
        'elementType': 'geometry',
        'stylers': [ { 'color': '#37465A' } ]
      },
      {
        'featureType': 'road.highway',
        'elementType': 'geometry',
        'stylers': [ { 'color': '#37465A' } ]
      },
      {
        'featureType': 'road.highway.controlled_access',
        'elementType': 'geometry',
        'stylers': [ { 'color': '#37465A' } ]
      },
      {
        'featureType': 'transit',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'water',
        'elementType': 'geometry',
        'stylers': [ { 'color': '#141E28' } ]
      }
    ]

    const mapStylingLight = [
      {
        'elementType': 'labels.icon',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'administrative',
        'stylers': [ { 'visibility': 'simplified' } ]
      },
      {
        'featureType': 'administrative',
        'elementType': 'labels',
        'stylers': [ { 'color': '#233241' } ]
      },
      {
        'featureType': 'administrative.country',
        'stylers': [ { 'visibility': 'on' } ]
      },
      {
        'featureType': 'administrative.country',
        'elementType': 'labels',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'administrative.neighborhood',
        'elementType': 'labels',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'administrative.province',
        'stylers': [ { 'visibility': 'on' } ]
      },
      {
        'featureType': 'administrative.province',
        'elementType': 'labels',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'landscape',
        'elementType': 'labels',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'landscape.man_made',
        'elementType': 'geometry.fill',
        'stylers': [ { 'color': '#E4EBF5' } ]
      },
      {
        'featureType': 'landscape.man_made',
        'elementType': 'geometry.stroke',
        'stylers': [ { 'color': '#B4C3D7' } ]
      },
      {
        'featureType': 'landscape.natural',
        'elementType': 'geometry.fill',
        'stylers': [ { 'lightness': 25 } ]
      },
      {
        'featureType': 'landscape.natural',
        'elementType': 'labels.text.fill',
        'stylers': [ { 'color': '#465569' } ]
      },
      {
        'featureType': 'poi',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'poi',
        'elementType': 'geometry.fill',
        'stylers': [ { 'color': '#D2DCEB' } ]
      },
      {
        'featureType': 'poi',
        'elementType': 'labels',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'poi.attraction',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'poi.place_of_worship',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'road',
        'elementType': 'geometry.fill',
        'stylers': [ { 'color': '#FFFFFF' } ]
      },
      {
        'featureType': 'road',
        'elementType': 'labels.icon',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'road',
        'elementType': 'labels.text.fill',
        'stylers': [ { 'color': '#73879B' } ]
      },
      {
        'featureType': 'road.highway',
        'elementType': 'geometry.stroke',
        'stylers': [ { 'color': '#FFC864' } ]
      },
      {
        'featureType': 'transit',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'transit',
        'elementType': 'labels',
        'stylers': [ { 'visibility': 'off' } ]
      },
      {
        'featureType': 'water',
        'elementType': 'geometry.fill',
        'stylers': [ { 'color': '#AFD7FF' } ]
      },
      {
        'featureType': 'water',
        'elementType': 'labels',
        'stylers': [ { 'color': '#2B6FAF' } ]
      },
      {
        'featureType': 'water',
        'elementType': 'labels.icon',
        'stylers': [ { 'visibility': 'off' } ]
      }
    ]

    if (type == "dark") {
      return mapStylingDark
    }
    else {
      return mapStylingLight
    }
  }
}
