import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "tab" ];

  #timeouts = {};

  toggle(event){
    this._setSelectedTab(event.currentTarget, false);
  }

  _getActiveTab() {
    return this.element.querySelector('[aria-selected="true"][role="tab"]');
  }

  _getPanelForTab(tab) {
    return this.element.querySelector(`#${tab.getAttribute('aria-controls')}`);
  }

  _setSelectedTab(currentTab, setFocus = true) {
    const prevActiveTab = this._getActiveTab();
    if (prevActiveTab === currentTab) return;

    prevActiveTab.setAttribute('aria-selected', 'false');
    prevActiveTab.classList.remove('active');
    prevActiveTab.tabIndex = -1;

    currentTab.setAttribute('aria-selected', 'true');
    currentTab.removeAttribute('tabindex');
    currentTab.classList.add('active');
    if (setFocus) currentTab.focus();

    const prevPanel = this._getPanelForTab(prevActiveTab);
    const panel = this._getPanelForTab(currentTab);

    clearTimeout(this.#timeouts[prevPanel.id]);
    clearTimeout(this.#timeouts[panel.id]);

    prevPanel.classList.add('opacity-0');
    this.#timeouts[prevPanel.id] = setTimeout(() => {
      prevPanel.classList.add('hidden');
    }, 300);

    panel.classList.remove('hidden');
    this.#timeouts[panel.id] = setTimeout(() => {
      panel.classList.remove('opacity-0');
    }, 50);
  }

  _focusPrevious() {
    const index = this.tabTargets.indexOf(this._getActiveTab());
    const previousTab = this.tabTargets[index - 1] || this.tabTargets[this.tabTargets.length - 1];
    this._setSelectedTab(previousTab);
  }

  _focusNext() {
    const index = this.tabTargets.indexOf(this._getActiveTab());
    const nextTab = this.tabTargets[index + 1] || this.tabTargets[0];
    this._setSelectedTab(nextTab);
  }

  onKeydown(keydownEvent) {
    let flag = false;
    switch (keydownEvent.key) {
      case 'ArrowUp':
        this._focusPrevious();
        flag = true;
        break;

      case 'ArrowDown':
        this._focusNext();
        flag = true;
        break;

      case 'Home':
        this._setSelectedTab(this.tabTargets[0]);
        flag = true;
        break;

      case 'End':
        this._setSelectedTab(this.tabTargets[this.tabTargets.length - 1]);
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      keydownEvent.stopPropagation();
      keydownEvent.preventDefault();
    }
  }
}
