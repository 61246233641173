import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fadeOut"];

  connect() {
    this.fadeOutTarget.classList.toggle(`fade-out-${this.fadeOutTarget.dataset.fadeOutHeight}`, true)
  }

  show(event /* event: StimulusEvent */) {
    event.currentTarget.remove();
    this.fadeOutTarget.classList.remove(`fade-out-${this.fadeOutTarget.dataset.fadeOutHeight}`);
  }
}
