import { Controller } from "@hotwired/stimulus"
import ab001ProminentBankingOverview from "../experiments/ab001-prominent-banking-overview";

export default class extends Controller {
  connect() {
    this.initExperiments(window.gb);
    this.watchSignupLinks();
  }

  initExperiments(gb) {
    if (!gb) return;

    ab001ProminentBankingOverview(gb);
  }

  watchSignupLinks() {
    const menuComponent = this.element.querySelector("menu-component");
    const footerComponent = this.element.querySelector("footer-component");
    const containers = [this.element, menuComponent.shadowRoot, footerComponent.shadowRoot];
    containers.forEach((container) => {
      let host = container.getRootNode().host?.tagName;
      container.querySelectorAll("a[href*='/aanmelden']").forEach((link, index) => {
        link.addEventListener("click", this._handleSignupLinkClick(host, index));
      });
    });
  }

  _handleSignupLinkClick = (host, index) => (event) => {
    const linkText = event.target.textContent.trim();
    let slice = event.target.closest("[data-slice]")?.dataset.slice;
    if (!slice && host) {
      slice = host.toLowerCase();
    }

    window.trackEvent({
      event: "signup_link_click",
      link_text: linkText,
      link_index: `${index}`,
      current_url: window.location.href,
      slice,
    });
  }
}
