import "$styles/index.css";
import { Application } from "@hotwired/stimulus";
import "lazysizes";
import "./async-loaded.js";
import "./recaptcha-submits.js";
import "./shadow-dom-clicks";
import "./webcomponents.js"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

// Import all StimulusJS controllers
import controllers from "./controllers/**/*.{js,js.rb}"
import component_controllers from "bridgetownComponents/**/*_controller.{js,js.rb}"

window.Stimulus = Application.start()

function requireStimulusControllers(controllers) {
  Object.entries(controllers).forEach(([filename, controller]) => {
    if (filename.includes("_controller.")) {
      const logicalName = (filename.match(/^(?:\.\/)?(.+)(?:[_-]controller\..+?)$/) || [])[1];
      const identifier = logicalName.split("/").pop().replace(/_/g, "-");

      window.Stimulus.register(identifier, controller.default)
    }
  })
}

requireStimulusControllers(controllers)
requireStimulusControllers(component_controllers)
