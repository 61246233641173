import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "widgetSection", "widgetPreview", "adviserName"];

  #selectChangeListener;
  #selectedAdviser;

  initialize() {
    this.#selectChangeListener = (event) => {
      this._adviserSelected(event.target.value);
    };
  }

  connect() {
    this.selectTarget.addEventListener('change', this.#selectChangeListener);
  }

  disconnect() {
    this.selectTarget.removeEventListener("change", this.#selectChangeListener);
  }

  _adviserSelected(adviser) {
    this.#selectedAdviser = adviser;
    const url = this._host() + '/experts/' + this.#selectedAdviser + '/widget.js';

    this._loadWidget(url);
  }

  _host() {
    return (window.location.host == 'localhost:4000' ? 'http://localhost:4000' : 'https://www.moneybird.nl');
  }

  _loadWidget(url) {
    const head = document.head;
    const script = document.createElement('script');
    const callbackName = 'widgetAdviser';

    script.type = 'application/javascript'
    script.setAttribute('src', url + '?=' + callbackName);

    window[callbackName] = (json) => {
      window[callbackName] = undefined;
      script.parentNode.removeChild(script);
      this._activateWidgetSection(json);
    }
    head.appendChild(script);
  }

  _activateWidgetSection(data) {
    this.widgetSectionTarget.classList.remove('is-loaded');

    if (this.widgetSectionTarget.classList.contains('hidden')){
      this.widgetSectionTarget.classList.remove('hidden');
      this._insertWidget(data);
    } else {
      this.widgetSectionTarget.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend'), () => {
        this._insertWidget(data);
      }
    }
  }

  _insertWidget(data) {
    this.widgetPreviewTargets.forEach(function(preview) {
      preview.innerHTML = data['html'];
    });

    this.widgetSectionTarget.scrollIntoView({ behavior: 'smooth'});

    this.widgetPreviewTargets.forEach(function(preview) {
      preview.classList.add('is-loaded');
    });

    const adviser_name = this.#selectedAdviser;

    this.adviserNameTargets.forEach(function(name) {
      name.replaceWith(adviser_name);
    });    
  }
}
