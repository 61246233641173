import { ApplicationController, useDebounce } from 'stimulus-use'
import gsap from 'gsap';

export default class extends ApplicationController {
  static debounces = ['search']
  static targets = ['searchResults', 'form', 'result', 'input', 'loader', 'error']

  static values = {
    noResultsLabel: String,
  }

  #currentSelection = -1;

  connect() {
    useDebounce(this, { wait: 300 });
  }

  inputChanged(event) {
    this.search(event.target.value);
  }

  async search(term) {
    this._showError(false);
    this._showSearchResults(term.length > 0);
    this._showLoader(true);

    try {
      const response = await fetch(`${this.formTarget.action}?query=${term}`);
      const data = await response.json();
      this._createSearchResults(data, term, this.searchResultsTarget);
    } catch (error) {
      console.error(error);
      this._showError(true);
    } finally {
      this._showLoader(false);
    }
  }

  keydown(keydownEvent) {
    let x = this.searchResultsTarget;
    if (x) x = x.getElementsByTagName("button");
    if (keydownEvent.keyCode === 40) {
      this.#currentSelection++;
      this._setSelection(x);
    } else if (keydownEvent.keyCode === 38) { //up
      this.#currentSelection--;
      this._setSelection(x);
    } else if (keydownEvent.keyCode === 13) { //enter
      keydownEvent.preventDefault();
      if (this.#currentSelection > -1) {
        if (x) x[this.#currentSelection].click();
      }
    }
  }

  _setSelection(x) {
    if (!x || x.length === 0) return false;

    // remove selections
    for (let i = 0; i < x.length; i++) {
      x[i].classList.remove("selected");
    }

    // cycle
    if (this.#currentSelection >= x.length) this.#currentSelection = 0;
    if (this.#currentSelection < 0) this.#currentSelection = (x.length - 1);

    x[this.#currentSelection].classList.add("selected");
  }

  _showSearchResults(enable) {
    this.inputTarget.classList.toggle("rounded-b-none", enable);
    gsap.to(this.searchResultsTarget, {
      opacity: enable ? 1 : 0,
      height: enable ? 'auto' : 0,
      duration: 0.3,
    });
  }

  _showSearchForm(enable) {
    this.formTarget.classList.toggle('overflow-hidden', !enable);
    gsap.to(this.formTarget, {
      opacity: enable ? 1 : 0,
      height: enable ? 'auto' : 0,
      duration: 0.3,
    });
  }

  _clearSearchResults(targetElement) {
    targetElement.innerHTML = "";
  }

  _createSearchResults(searchResults, searchTerm, targetElement) {
    this._clearSearchResults(targetElement)
    searchResults.forEach(searchResult => {
      this._createSearchResultElement(searchResult, searchTerm, targetElement)
    });

    if (!searchResults.length) {
      this._createSearchResultElement({
        name: this.noResultsLabelValue,
        city: "",
        coc_number: "",
        accepted: false,
        type: "no-results",
      }, searchTerm, targetElement);
    }
  }

  _createSearchResultElement(searchResult, searchTerm, targetElement) {
    const entryElement = document.createElement("button");
    entryElement.setAttribute("type", "button");
    entryElement.setAttribute("data-name", searchResult.name);
    entryElement.setAttribute("data-city", searchResult.city);
    entryElement.setAttribute("data-coc-number", searchResult.coc_number);
    entryElement.setAttribute("data-accepted", searchResult.accepted);
    if (searchResult.type !== "no-results") {
      entryElement.setAttribute("data-action", "click->banking-signup-duration-calculator#select");
      entryElement.classList.add("hover:bg-n100", "[&.selected]:bg-n100");
    }

    entryElement.classList.add("w-full", "flex", "flex-col", "defaults-white", "border-b", "border-n100", "py-2", "px-4", "transition-colors", "text-left");
    entryElement.innerHTML = "<span class='text-dark-blue'>" + searchResult.name + "</span>";
    if (searchResult.city || searchResult.coc_number) {
      entryElement.innerHTML += "<span class='text-dark-blue/80 text-sm'>" + (searchResult.city ? `${searchResult.city} • ` : '') + "KVK nr.: " + searchResult.coc_number + "</span>";
    }

    targetElement.appendChild(entryElement);
  }

  select(event) {
    const element = event.currentTarget;
    const name = element.getAttribute("data-name");
    const city = element.getAttribute("data-city");
    const cocNumber = element.getAttribute("data-coc-number");
    const accepted = element.getAttribute("data-accepted") === "true";

    this._showSearchResults(false);
    this._showSearchForm(false);

    // send an event
    this.element.dispatchEvent(new CustomEvent("banking-signup-duration-calculator:select", {
      bubbles: true,
      detail: {
        name: name,
        city: city,
        cocNumber: cocNumber,
        accepted: accepted,
      },
    }));

    dataLayer.push({
      event: "gb_banking_signup_duration_calculator_select",
      name: name,
      city: city,
      cocNumber: cocNumber,
      accepted: accepted,
    });

    if (accepted) {
      this.resultTarget.innerHTML = `<h3>Goed nieuws!</h3><p>${name} uit ${city} kan binnen een dag een bankrekening bij Moneybird openen!</p>`;
    } else {
      this.resultTarget.innerHTML = `<p>Helaas kunnen we op dit moment geen zakelijke rekening voor ${name} openen. Wel kan je jouw volledige boekhouding bij Moneybird regelen en een externe rekening koppelen.</p>`;
    }
    this.resultTarget.parentElement.classList.remove("opacity-0", "h-0");
  }

  _showLoader(enable) {
    this.loaderTarget.classList.toggle("opacity-0", !enable);
    this.loaderTarget.classList.toggle("animate-spin", enable);
  }

  _showError(enable) {
    this.inputTarget.classList.toggle("rounded-b-none", enable);
    this.errorTarget.classList.toggle("hidden", !enable);
    if (enable) {
      dataLayer.push({
        event: "gb_banking_signup_duration_calculator_error",
      });
    }
  }

  reset() {
    this.resultTarget.parentElement.classList.add("opacity-0", "h-0");
    this.inputTarget.value = "";
    this.inputTarget.focus();
    this._showSearchForm(true);
    this.element.dispatchEvent(new CustomEvent("banking-signup-duration-calculator:reset", {
      bubbles: true,
    }));

    dataLayer.push({
      event: "gb_banking_signup_duration_calculator_reset",
    });
  }
}