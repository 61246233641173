import { Controller } from "@hotwired/stimulus";
import { useResize } from "stimulus-use";

export default class extends Controller {
  static targets = ["list", "item"];

  connect() {
    useResize(this);
  }

  resize() {
    // We want to know how many items there are per row, so we can adjust the width of each item to balance the layout
    const itemsPerRow = this.itemTargets.reduce((acc, item) => {
      item.removeAttribute("style");
      if (!acc[item.offsetTop]) acc[item.offsetTop] = [];
      acc[item.offsetTop].push(item);
      return acc;
    }, {});

    const nItems = this.itemTargets.length;
    const nRows = Object.keys(itemsPerRow).length;

    if (nRows === 1) return;

    const nPerRow = Math.ceil(nItems / nRows);
    const flexBasis = `${100 / nPerRow}%`;
    this.itemTargets.forEach((item, index) => {
      item.style.flexBasis = flexBasis;

      const itemInRowIndex = index % nPerRow;
      if (itemInRowIndex === 0) {
        item.style.borderLeft = 0;
      }
    });
  }
}
