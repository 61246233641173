import { Controller } from "@hotwired/stimulus";
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class extends Controller {
  static targets = ["counter"];

  formatNumber(value, decimals) {
    let s = (+value).toLocaleString('nl-NL').split(",");
    return decimals ? s[0] + "." + ((s[1] || "") + "00000000").substr(0, decimals) : s[0];
  }

  connect() {
    if (!this.hasCounterTarget) return;

    gsap.from(this.counterTargets, {
      textContent: "0",
      duration: 1,
      ease: "power1.inOut",
      modifiers: {
        textContent: value => this.formatNumber(value, 0)
      },
      scrollTrigger: {
        trigger: this.element,
        start: "50px 90%",
        end: "+=100",
        toggleActions: "play none none none",
        markers: false
      }
    });
  }
}
