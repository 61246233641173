function handleControlVariantDomMutations() {
  const carousel = document.querySelector('#page-title .swiper');
  if (carousel) {
    const text = carousel.querySelector('.swiper-slide:first-child').innerText;
    // replace the carousel with the text
    carousel.replaceWith(document.createTextNode(text));
  }

  document.querySelectorAll("[data-slice='intro']").forEach((el) => {
    if (!el.textContent.includes('dag een zakelijke rekening')) return;

    el.remove();
  });

  document.querySelectorAll("[data-slice='banking-signup-duration-calculator']").forEach((el) => {
    el.remove();
  });
}

function handleDefaultVariantDomMutations() {
  const firstImage = 'https://images.prismic.io/moneybird/d4c5d8d5-6a04-46f7-b328-8dd7f380d0ce_Afbeelding+-+1424x801+-+Campagne+-+Fotograaf+-+Automatisering.jpg?auto=format,compress&fit=crop&crop=entropy';
  const slide = document.querySelector('header .carousel[data-controller="carousel"] .swiper-slide:nth-child(2)');
  slide.querySelectorAll('source').forEach((el, index) => {
    el.setAttribute('srcset', `${firstImage}&w=956&ar=${index === 0 ? '16:9' : '1:1.1'}`);
  });
  slide.querySelector('img').setAttribute('src', `${firstImage}&w=1600`);

  const secondImage = 'https://images.prismic.io/moneybird/65cc8d599be9a5b998b5cb7b_Afbeelding-1032x1032-Moneybirdbetaalpasbijlaptop.png?auto=format,compress&fit=crop';
  const secondSlide = document.querySelector('header .carousel[data-controller="carousel"] .swiper-slide:nth-child(3)');
  secondSlide.querySelectorAll('source').forEach((el, index) => {
    el.setAttribute('srcset', `${secondImage}&w=956&ar=${index === 0 ? '16:9' : '1:1.1'}`);
  });
  secondSlide.querySelector('img').setAttribute('src', `${secondImage}&w=1600`);
}

export default function(gb) {
  const feature = gb.evalFeature("prominent-banking-overview");

  // only on the homepage
  if (!['/', '/nl/'].includes(window.pageProperties?.relative_url)) return;

  if (feature.on && feature.value === 1) {
    handleDefaultVariantDomMutations();
  } else {
    handleControlVariantDomMutations();
  }
}