var webcomponents = ["menu-component", "footer-component", "mb-cookie-modal-component"];
webcomponents.forEach((component) => {
  customElements.define(
    component,
    class extends HTMLElement {
      constructor() {
        super();
        const template = document.getElementById(component);
        if (!template) {
          console.error(`Template with id ${component} not found`);
          return;
        }

        const templateContent = template.content;
        const customHomeUrl = this.getAttribute("home-url");
        if (customHomeUrl) {
          const homeLinks = templateContent.querySelectorAll('a[data-url="home"]');
          homeLinks.forEach((link) => {
            link.href = customHomeUrl;
          });
        }

        const activePageUrl = this.getAttribute("active-page");
        if (activePageUrl && activePageUrl !== "/") {
          const links = templateContent.querySelectorAll('.menu-bar-link');
          links.forEach((link) => {
            if (link.href?.endsWith(activePageUrl)) {
              link.classList.add("active");
            }
          });
        }

        const shadowRoot = this.attachShadow({ mode: "open" });
        shadowRoot.appendChild(templateContent.cloneNode(true));

        // remove the template from the DOM
        template.remove();
      }
    }
  );
});
