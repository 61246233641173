// Set to the event you want to track
const eventName = 'click';
// Set to false if you don't want to use capture phase
const useCapture = true;
// Set to false if you want to track all events and not just those in shadow DOM
const trackOnlyShadowDom = true;

const callback = function(event) {
  if ('composed' in event && typeof event.composedPath === 'function') {
    // Get the path of elements the event climbed through, e.g.
    // [span, div, div, section, body]
    const path = event.composedPath();

    // Fetch reference to the element that was actually clicked
    const targetElement = path[0];

    // Check if the element is WITHIN the shadow DOM (ignoring the root)
    const shadowFound = path.length ? path.filter(function(i) {
      return !targetElement.shadowRoot && !!i.shadowRoot;
    }).length > 0 : false;

    // If only shadow DOM events should be tracked and the element is not within one, return
    if (trackOnlyShadowDom && !shadowFound) return;

    window.dataLayer = window.dataLayer || [];

    const targetLink = targetElement.closest('a');
    const element = targetLink || targetElement;

    // Push to dataLayer
    window.dataLayer.push({
      event: 'custom_event_' + event.type,
      custom_event: {
        element: element,
        elementId: element.id || '',
        elementClasses: element.className || '',
        elementUrl: element.href || element.action || '',
        elementTarget: element.target || '',
        originalEvent: event,
        inShadowDom: shadowFound
      }
    });
  }
};

document.addEventListener(eventName, callback, useCapture);