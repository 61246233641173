import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fadeOut"];

  connect() {
    document.body.addEventListener("modals:close", this.closeOpenModals)
  }

  openModal(event /* event: StimulusEvent */) {
    const modal = document.getElementById(event.currentTarget.dataset.modalId);
    modal.classList.remove("hidden");
    this._addContextEventListener(modal);
    document.body.classList.add("h-screen");
    document.body.classList.add("overflow-y-hidden");
  }

  closeOpenModals() {
    document.querySelectorAll("[data-modal]").forEach((el) => {
      el.classList.add("hidden", true);
    });

    document.body.classList.remove("h-screen");
    document.body.classList.remove("overflow-y-hidden");
  }

  _addContextEventListener(modal) {
    const focusableContent = modal.querySelectorAll('button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])');
    const firstFocusableElement = focusableContent[0];
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    document.addEventListener('keydown', (e) => {
      let isTabPressed = e.key === 'Tab' || e.code === 9;

      if (!isTabPressed) return;

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          firstFocusableElement.focus();
          e.preventDefault();
        }
      }
    });

    firstFocusableElement.focus();
  }
}



