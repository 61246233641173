import gsap from "gsap";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    viewMoreLabel: String,
    viewLessLabel: String,
  }

  static targets = [ "item", "defaultHiddenItem" ];

  toggle(event){
    const item = event.currentTarget.closest("[data-expandable-list-target*='item']");
    const targets = this._getTargets(item);
    const isActive = item.classList.contains('active');

    if (!isActive) {
      this._closeOthers(item);
      this._animate(targets, true);
    } else {
      this._animate(targets, false);
    }
  }

  showMore(event) {
    const textNode = event.currentTarget.childNodes[0];
    const isExpanded = textNode.nodeValue.includes(this.viewLessLabelValue);
    event.currentTarget.querySelector('.fas').classList.toggle('-rotate-180');
    textNode.nodeValue = isExpanded ? this.viewMoreLabelValue : this.viewLessLabelValue;

    this.defaultHiddenItemTargets.forEach(item => {
      if (item.classList.contains('hidden')) {
        gsap.fromTo(item, { opacity: 0, height: 0 }, { height: 'auto', opacity: 1, duration: 0.3 });
        item.classList.remove('hidden');
      } else {
        gsap.to(item, { height: 0, opacity: 0, duration: 0.3, onComplete: () => { item.classList.add('hidden') } });
      }
    });
  }

  _getTargets(item) {
    return {
      root: item,
      content: item.querySelector(".expandable-list_content"),
      header: item.querySelector(".expandable-list_header"),
      icon: item.querySelector(".expandable-list_icon"),
    }
  }

  _animate(targets, show) {
    targets.root.classList.toggle('active', show);
    const contentAnimation = { height: show ? "auto" : 0, duration: 0.3 };
    if (show) targets.content.classList.remove('hidden');
    if (!show) contentAnimation.onComplete = () => { targets.content.classList.add('hidden') };

    gsap.to(targets.content, contentAnimation);
    gsap.to(targets.root, { paddingBottom: show ? 16 : 0, duration: 0.3 });
    gsap.to(targets.icon, { rotate: show ? 45 : 0, duration: 0.3 });
    this._setAriaAttributes(targets, show);
  }

  _closeOthers(item) {
    for (const target of this.itemTargets) {
      if (target === item) continue;
      if (!target.classList.contains('active')) continue;

      const targets = this._getTargets(target);
      this._animate(targets, false);
    }
  }

  _setAriaAttributes(targets, expanded) {
    targets.header.setAttribute("aria-expanded", expanded);
    targets.content.setAttribute("aria-hidden", !expanded);
  }
}
