import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "dropdown" ]

  close(){
    this.dropdownTarget.classList.remove("active")
  }

  open(){
    this.dropdownTarget.classList.add("active")
  }
}
