import { Controller } from "@hotwired/stimulus";
import gsap from 'gsap';
import Flip from "gsap/Flip";

gsap.registerPlugin(Flip);

export default class extends Controller {
  static targets = ["button", "backdrop", "tab"];

  connect() {
    this.tabs = gsap.utils.toArray([...this.tabTargets, ...this.backdropTargets, this.element]);
  }

  toggle(event) {
    const state = Flip.getState(this.tabs);

    const targetId= event.currentTarget.getAttribute('aria-controls');
    let prev = this.tabTargets.findIndex((tab) => tab.getAttribute('aria-hidden') === 'false');
    let next = this.tabTargets.findIndex((tab) => tab.id === targetId);
    const direction = next > prev ? 1 : -1;

    this.buttonTargets.forEach((button) => {
      const isSelected = button === event.currentTarget;
      button.setAttribute("aria-selected", isSelected);
      button.style.zIndex = isSelected ? 4 : 5;

      if (isSelected) {
        // move backdrop element inside this button
        button.appendChild(this.backdropTarget);
      }
    });

    this.tabTargets.forEach((tab) => {
      const isSelected = tab.id === targetId;
      tab.setAttribute("aria-hidden", !isSelected);
    });

    Flip.from(state, {
      absolute: true,
      absoluteOnLeave: true,
      duration: 0.3,
      ease: "power1.inOut",
      onEnter: elements => gsap.fromTo(elements, {opacity: 0, x: -20 * direction}, {opacity: 1, x: 0, duration: 0.4, ease: "power1.inOut" }),
      onLeave: elements => gsap.to(elements, {opacity: 0, x: 20 * direction, duration: 0.4, ease: "power1.inOut" }),
    });
  }
}
