import { Controller } from "@hotwired/stimulus"
import gsap from 'gsap';
import Flip from "gsap/Flip";

gsap.registerPlugin(Flip)

export default class extends Controller {
    static targets = [ "head", "select" ];

    #columns = [];

    connect() {
        this._setColumns();
        this._toggleOptions();

        const observer = new IntersectionObserver(
          ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
          { threshold: [1] }
        );
        observer.observe(this.headTarget);
    }

    toggleTableBody(e) {
        const button = e.currentTarget;
        const body = button.closest('tbody');
        const bodyToggleClasses = ['!block', '[&>tr]:!flex', 'collapsed'];
        const isCollapsed = body.classList.contains('collapsed');
        const state = Flip.getState(body);
        if (isCollapsed) {
            body.style.height = 'auto';
            button.closest('div').classList.remove('rounded-3xl');
            button.querySelector('i').classList.remove('rotate-180');
        } else {
            body.style.width = body.offsetWidth + 'px';
            body.style.height = body.offsetHeight + 'px';
            body.classList.add(...bodyToggleClasses);
            body.style.height = button.closest('tr').offsetHeight + 'px';
            button.querySelector('i').classList.add('rotate-180');
        }
        Flip.from(state, {
            duration: 0.3,
            onComplete: () => {
               if (isCollapsed) {
                   body.removeAttribute('style');
                   body.classList.remove(...bodyToggleClasses);
               } else {
                   button.closest('div').classList.add('rounded-3xl');
               }
            }
        });
    }

    filter(e) {
        const currentValue = e.target.dataset.value;
        const nextValue = e.target.value;

        this.element.querySelectorAll('tr').forEach((tr) => {
            const currentCell = tr.querySelector(`[data-column=${currentValue}]`);
            const nextCell = tr.querySelector(`[data-column=${nextValue}]`);
            if (!currentCell || !nextCell) return;
            currentCell.classList.add("hidden");
            nextCell.classList.remove("hidden")
            tr.insertBefore(nextCell, currentCell);
        });

        e.target.dataset.value = nextValue;
        this._setColumns();
        this._toggleOptions();
    }

    _setColumns() {
        const options = [...this.selectTarget.querySelectorAll("option")]
        this.#columns = options.map(({ parentNode, dataset, value }) => ({
            key: value,
            active: !this.element.querySelector(`tbody td[data-column=${value}]`).classList.contains("hidden"),
        }));
    }

    _toggleOptions(selects, current, next) {
        this.selectTargets.forEach((select) => {
            const nextValue = select.dataset.value;
            select.querySelectorAll("option").forEach((option) => {
                const isActive = this.#columns.find((c) => c.key === option.value).active;
                if (isActive && option.value !== nextValue) {
                    option.setAttribute("disabled", true);
                } else {
                    option.removeAttribute("disabled");
                }
            });
        });
    }
}