import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "head", "select" ];

  connect() {
    const observer = new IntersectionObserver(
      ([e]) => {
        e.target.classList.toggle("[&_th]:rounded-none", e.intersectionRect.top === 0)
        e.target.classList.toggle("stuck", e.intersectionRect.top === 0)
      },
      { threshold: [1] }
    );
    if (window.innerWidth < 768) {
      observer.observe(this.headTarget);
    }
  }
}