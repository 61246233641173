import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import {
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";

export default class extends Controller {
  static targets = ["track", "slide"];

  connect() {
    if (this.slideTargets.length < 2) return;

    this.swiper = new Swiper(this.element, {
      modules: [A11y, Autoplay],
      ...this.defaultOptions,
    });
    window.headerTextCarousel = this.swiper;
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }

  get defaultOptions() {
    return {
      loop: true,
      direction: "vertical",
      allowTouchMove: false,
      speed: 1000,
      ...(!this._hasConnectedCarousel() && {
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          waitForTransition: false,
          stopOnLastSlide: false,
        },
      }),
    };
  }

  // private

  _hasConnectedCarousel() {
    return document.querySelector("header [data-controller='carousel']");
  }
}
