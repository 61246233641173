import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {
  connect() {
    let isEditor = Cookies.get('is_editor') || Cookies.get('io.prismic.preview');

    if (isEditor) {
      Cookies.set('is_editor', true, { expires: 14 });
      this._createEditButton();
    }
  }

  _getPrismicUrl() {
    return `https://moneybird.prismic.io/builder/pages/${this.element.dataset.prismicPageId}?s=published`;
  }

  _createEditButton() {
    const editButton = document.createElement('a');
    editButton.href = this._getPrismicUrl();
    editButton.target = '_blank';
    editButton.innerText = 'Open in Prismic';
    editButton.classList.add('bg-y300', 'hover:bg-y400', 'text-black', 'hover:text-white', 'px-4', 'py-2', 'rounded-full', 'rounded-br-lg', 'z-40', 'fixed', 'right-[1.85rem]', 'bottom-20', 'transition-all');

    this.element.classList.remove('hidden');
    this.element.appendChild(editButton);
  }
}
